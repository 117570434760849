* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap");
@font-face {
  font-family: "Circular";
  src: url(./fonts/Circular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Circular";
  src: url(./fonts/CircularMed.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Circular";
  src: url(./fonts/CircularBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Circular";
  src: url(./fonts/CircularBlack.ttf);
  font-weight: 700;
}

body {
  background-color: #000000;
  font-family: "Circular";
  color: #ffffff;
  font-weight: 400;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 5rem;
}

main {
  max-width: 80rem;
  margin: 0 auto;
}

input:focus-within {
  box-shadow: 0 0 0 2px #ffffff;
}

.flex {
  display: flex;
}

.al-ce {
  align-items: center;
}

.jc-ce {
  justify-content: center;
}

.flex-col {
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fe {
  justify-content: flex-end;
}

.al-fe {
  align-items: flex-end;
}

::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background: #888;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
  color: inherit;
}

.mt-0 {
  margin-top: 0 !important;
}

.smallheader {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.875rem;
  text-align: center;
  color: #ffffff;
}

.largeheader {
  font-weight: 500;
  font-size: 5.625rem;
  line-height: 7.125rem;
  text-align: center;
  color: #ffffff;
  margin-top: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bluebg {
  padding: 0 1rem;
  margin-right: 1rem;
  background: #7c7ff3;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  margin-left: 1rem;
  -o-border-radius: 0.9375rem;
  overflow: hidden;
  max-height: 6.875rem;
  display: flex;
  align-items: center;
}

.redbg {
  padding: 0 1rem;
  background: #ff88de;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  margin-left: 1rem;
  -o-border-radius: 0.9375rem;
  max-height: 6.875rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.text {
  justify-content: center;
}

.button {
  padding: 0.875rem 1.25rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  color: #ffffff;
  background: #121212;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #2e2b2b;
  }

  &.activeButton {
    background-color: #2e2b2b;
  }
}

.btns {
  margin-top: 3rem;
  margin-bottom: 5.25rem;
  .button:nth-child(2) {
    margin: 0 1.625rem;
  }
}

.transform {
  transform: translateY(2.5rem);
  -webkit-transform: translateY(2.5rem);
  -moz-transform: translateY(2.5rem);
  -ms-transform: translateY(2.5rem);
  -o-transform: translateY(2.5rem);
}

.smalltext {
  font-style: normal;
  font-weight: 450;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  color: #717171;
}

.largetext {
  font-weight: 450;
  font-size: 5.625rem;
  line-height: 7.125rem;
  text-align: center;
  color: #ffffff;
}

.featuressection {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background: #121212;
  border-radius: 0.625rem;
  min-height: 16.5rem;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;

  .border {
    background-color: #232323;
    width: 0.0625rem;
    height: 80%;
  }

  .rightsection {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      max-width: 450px;
    }
  }
}

.center {
  justify-content: center !important;
}

#root > div > div:nth-child(4) {
  margin-top: 4rem;
}

.header {
  font-weight: 450;
  font-size: 4rem;
  line-height: 5.0625rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 5.25rem;
  color: #ffffff;
  margin-bottom: 5.625rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6rem;
  padding: 0 3.75rem;
  max-width: 90%;
  margin: auto;
  margin-bottom: 8rem;
}

.icon {
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #121212;
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  margin-bottom: 0.6875rem;
}

.title {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
}

.description {
  font-weight: 450;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #717171;
}

footer {
  width: 100%;
  background: #121212;

  .logocontainer {
    padding-right: 4rem;
  }

  .footerwrapper {
    max-width: 80rem;
    width: 60%;
    margin: auto;
    padding: 5.25rem 0 7.25rem 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    span {
      font-weight: 450;
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-align: center;
      color: #ffffff;
    }

    a {
      display: block;
      position: relative;
      font-weight: 450;
      font-size: 1.125rem;
      width: max-content;
      line-height: 1.4375rem;
      margin-top: 1.125rem;
      color: #ffffff;

      &::after {
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        content: "";
        width: 0;
        height: 0.125rem;
        background: #ffffff;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footerwrapper,
  main {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .featuressection .rightsection img {
    max-width: 335px;
  }
}

@media (max-width: 900px) {
  .featuressection {
    grid-template-columns: 1fr;
    max-width: 100%;
    width: 100%;
  }

  .footerwrapper {
    align-items: flex-start !important;
    justify-content: start !important;
    flex-direction: column;
  }

  .largetext {
    font-size: 4.625rem;
  }

  footer .footerwrapper span {
    margin-top: 3rem;
    text-align: left;
    display: block;
  }

  .featuressection .rightsection img {
    margin: auto;
    border-radius: 1.25rem 1.25rem 0 0;
    -webkit-border-radius: 1.25rem 1.25rem 0 0;
    -moz-border-radius: 1.25rem 1.25rem 0 0;
    -ms-border-radius: 1.25rem 1.25rem 0 0;
    -o-border-radius: 1.25rem 1.25rem 0 0;
    margin-top: 2rem;
  }

  .transform {
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
}

@media (max-width: 600px) {
  :root {
    font-size: 14px;
  }

  br {
    display: block;
  }

  .App {
    padding-top: 3rem;
  }

  .btns {
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
  }

  .button {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 1.25rem !important;
  }

  .smallheader {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .largeheader {
    font-size: 3.5rem;
    flex-direction: column;
    line-height: 6.125rem;
  }

  .bluebg,
  .redbg {
    max-height: 4.875rem;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: 100%;
    grid-gap: 3rem;
    padding: 0 0rem;
  }

  .button {
    font-size: 16px;
    height: 3.75rem;
    display: flex;
    align-items: center;
  }

  .description {
    font-size: 16px;
    line-height: 1.25;
  }

  .featuressection {
    padding-top: 1.25rem;
  }

  .footerwrapper {
    padding: 3.25rem 2rem 2.25rem 2rem !important;
  }

  .header {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 3.625rem;
  }

  .largetext {
    font-size: 2.625rem;
    line-height: 3.125rem;
    margin-bottom: 0.5rem;
  }

  .featuressection .rightsection img {
    width: 90%;
    margin-bottom: 0;
    object-fit: contain;
    margin-top: 1.5rem;
  }

  .smalltext {
    max-width: 25ch;
    margin: auto;
  }

  br {
    display: none;
  }
}
